import React, {useRef} from 'react';
import {Box, Button, Icon, Input} from '@chakra-ui/react';
import {FaFileUpload} from 'react-icons/fa';

function FileUploadComponent({onChat}) {
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = function (e) {
                const contents = e.target.result;
                console.log(contents)
                onChat(contents)
            };

            reader.readAsText(file);
        }
    };

    return (
        <Box position="relative" p={2}>
            <Input
                p={2}
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                opacity="0"
                position="absolute"
                top="0"
                left="2"
                width="100%"
                height="100%"
                zIndex="1"
            />
            <Button leftIcon={<Icon as={FaFileUpload}/>} colorScheme="blue">
            </Button>
        </Box>
    );
}

export default FileUploadComponent;
