// loadWasm.js
/* global Go */

export async function loadWasm(wasmFile) {
    var go = new Go();
    const response = await fetch(wasmFile);
    const buffer = await response.arrayBuffer();
    const { instance } = await WebAssembly.instantiate(buffer, go.importObject);
    go.run(instance);
    return instance.exports;
}
