import { Box, Spinner } from "@chakra-ui/react";

function LoadingThrobber() {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" >
            <Spinner size="m" speed="0.65s" thickness="4px" color="blue.500" />
        </Box>
    );
}

export default LoadingThrobber;