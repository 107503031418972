import * as React from "react";
import {useEffect} from "react";
import {Box, Button, Center, VStack} from "@chakra-ui/react";
import ChatBubble from "./ChatBubble";
import {ChatInput} from "./index";
import {chat, clear, retrieve} from "../Api";
import LoadingThrobber from "./LoadingThrobber";
import SelectLevelComponent from "./SelectLevelComponent";
import {useLevel} from "../Logic/LevelContext";

function ChatScreen() {

    const [messages, setMessages] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const {level, setLevel, setMaxLevel} = useLevel();

    useEffect(() => {
        setLoading(true);
        retrieve(level).then((response) => {
            setMessages(response["chats"]);
            setLoading(false);
            setLevel(response["level"]);
            setMaxLevel(response["maxLevel"]);
        }).catch((error) => {
            console.error(error);
        });
    }, [level, setLevel, setMaxLevel])

    const clearChat = () => {
        setLoading(true);
        clear().then((response) => {
            setMessages(response["chats"]);
            setLoading(false);
            setLevel(response["level"]);
            setMaxLevel(response["maxLevel"]);
        }).catch((error) => {
            console.error(error);
        });
    };

    const sendMessage = (text) => {
        setMessages([...messages, {role: "user", content: text}]);
        setLoading(true);
        chat(text, level).then((response) => {
            setMessages(response["chats"]);
            setLoading(false);
            setLevel(response["level"]);
            setMaxLevel(response["maxLevel"]);
        }).catch((error) => {
            setMessages([...messages, {role: "system", content: `${error}`}])
        });
    };

    return (
        <Center>
            <VStack
                spacing={4}
                w={{base: "90%", md: "50%"}}
            >
                <Button onClick={clearChat} position="absolute" top="5px" right="5px">
                    Clear Chat
                </Button>
                {messages.map((message, index) => (
                    <Box key={index}>
                        <ChatBubble mode={message.role} text={message.content}/>
                    </Box>
                ))}
                {loading && <LoadingThrobber/>}
                <ChatInput onSend={(text) => sendMessage(text)}/>
                <SelectLevelComponent/>
            </VStack>
        </Center>
    );
}

export default ChatScreen;
