import * as React from "react";
import {Button, Flex, Textarea} from "@chakra-ui/react";
import FileUploadComponent from "./FileUploadComponent";

function ChatInput({onSend}) {
    const [text, setText] = React.useState("");

    const handleInputChange = (event) => {
        setText(event.target.value);
    };

    const handleSendClick = () => {
        if (text.trim() !== "") {
            onSend(text);
            setText("");
        }
    };

    const handleFileUpload = (contents) => {
        onSend(contents);
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter" && !event.shiftKey && text.trim() !== "") {
            event.preventDefault();  // To prevent the default behavior (newline) when Enter is pressed
            onSend(text);
            setText("");
        }
    };

    return (
        <Flex
            bottom={0}
            width="100%"
            p={2}
            bg="white"
            alignItems="center"
        >
            <Textarea
                value={text}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Type your message..."
                flexGrow={1}
                rows={3}
            />
            <FileUploadComponent onChat={handleFileUpload}/>
            <Button ml={2} onClick={handleSendClick} colorScheme="blue">
                Send
            </Button>
        </Flex>
    );
}

export default ChatInput;
