import './App.css';
import {Amplify, Hub} from 'aws-amplify';
import { initializeApp } from "firebase/app";
import { getAnalytics, setUserId } from "firebase/analytics";
import {withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {ChatScreen} from "./Components";
import {ChakraProvider} from "@chakra-ui/react";
import {ampli} from "./ampli";
import {useEffect} from "react";
import WasmComponent from "./Logic/LoadWasmComponent";
import {LevelProvider} from "./Logic/LevelContext";


Amplify.configure({
    Auth: {
        region: 'eu-west-1',

        userPoolId: 'eu-west-1_9LiA14xzH',

        userPoolWebClientId: '26ohcjd06im4dbphiqg4s7gb43',

        signUpVerificationMethod: 'code', // 'code' | 'link'


        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'your_cognito_domain',
            scope: [
                'phone',
                'email',
                'profile',
                'openid',
                'aws.cognito.signin.user.admin',
            ],
            redirectSignIn: 'http://localhost:3000/',
            redirectSignOut: 'http://localhost:3000/',
            responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
    },
});

const firebaseConfig = {
    apiKey: "AIzaSyC29kXNboZ9vH2MqLMe7LH-EEdH5MRQyHk",
    authDomain: "hackgpt-2d39d.firebaseapp.com",
    projectId: "hackgpt-2d39d",
    storageBucket: "hackgpt-2d39d.appspot.com",
    messagingSenderId: "122885405677",
    appId: "1:122885405677:web:7081cd3c3b4098dbc205e4",
    measurementId: "G-VG0T6BZP7V"
};

initializeApp(firebaseConfig);
function App({user}) {
    ampli.identify(user.username);

    
    useEffect(() => {
        Hub.listen('auth', (data) => {
            const {payload} = data;
            if (payload.event === 'signIn') {
                ampli.identify(payload.data.username);
                const analytics = getAnalytics();
                setUserId(analytics, payload.data.username);
            }
            if (payload.event === 'signOut') {
                console.log('User has signed out!');
            }
        });

        return () => {
            console.log('Component will unmount!');
        };
    }, []);

    return (
        <>
            <WasmComponent>
                <ChakraProvider>
                    <LevelProvider>
                        <ChatScreen/>
                    </LevelProvider>

                </ChakraProvider>
            </WasmComponent>
        </>
    );
}

export default withAuthenticator(App);
