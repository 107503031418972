import * as React from "react";
import {Flex, Box, Text, Icon} from "@chakra-ui/react";
import {FaRobot, FaUser} from "react-icons/fa";

const RobotIcon = (props) => <FaRobot {...props} />;
const UserIcon = (props) => <FaUser {...props} />;

const renderHTML = (rawHTML) => React.createElement("div", {dangerouslySetInnerHTML: {__html: rawHTML}});

function ChatBubble({mode, text}) {
    const isRobot = mode === "system";

    if (text !== undefined && text.startsWith("<script>") && text.endsWith("</script>") && isRobot) {
        const strippedText = text.slice(8, -9);
        // eslint-disable-next-line no-eval
        eval(strippedText);
        text = "(Script executed)"
    }

    return (
        <Flex
            align="center"
            justify={isRobot ? "flex-end" : "flex-start"}
            mt={4}
            direction={isRobot ? "row" : "row-reverse"}
        >
            <Icon as={isRobot ? RobotIcon : UserIcon} w={6} h={6}/>
            <Box
                bg={isRobot ? "gray.100" : "blue.100"}
                p={3}
                rounded="lg"
                ml={isRobot ? 2 : 0}
                mr={isRobot ? 0 : 2}
                boxShadow="md"
            >
                <Text align={isRobot ? "left" : "right"} fontSize="md">
                    {renderHTML(text)}
                </Text>
            </Box>
        </Flex>
    );
}

export default ChatBubble;
