import React, { createContext, useContext, useState } from 'react';

const LevelContext = createContext();

export const useLevel = () => {
    return useContext(LevelContext);
};

export const LevelProvider = ({ children }) => {
    const [level, setLevel] = useState(-1);
    const [maxLevel, setMaxLevel] = useState(-1);

    return (
        <LevelContext.Provider value={{ level, setLevel, maxLevel, setMaxLevel }}>
            {children}
        </LevelContext.Provider>
    );
};
