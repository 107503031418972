import React, {useEffect, useState} from 'react';
import {loadWasm} from './loadWasm';

function WasmComponent({children}) {
    const [wasm, setWasm] = useState(null);

    useEffect(() => {
        loadWasm('/json.wasm')
            .then((wasmModule) => {
                setWasm(wasmModule);
            })
            .catch((error) => console.error(error));
    }, []);

    return (
        <div>
            {wasm && children}
        </div>
    );
}

export default WasmComponent;
