import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
} from '@chakra-ui/react';
import { useLevel } from '../Logic/LevelContext';

const SelectLevelComponent = () => {
    const { level, setLevel, maxLevel } = useLevel();

    const menuItems = [];
    for (let i = 1; i <= maxLevel; i++) {
        menuItems.push(
            <MenuItem key={i} onClick={() => setLevel(i)}>
                {`Level ${i}`}
            </MenuItem>
        );
    }

    return (
        <Menu>
            <MenuButton as={Button}>
                {`Level: ${level}`}
            </MenuButton>
            <MenuList>
                {menuItems}
            </MenuList>
        </Menu>
    );
};

export default SelectLevelComponent;
