import {Auth} from 'aws-amplify';


export function chat(prompt, desiredLevel) {
    return Auth.currentSession()
        .then(async session => {
            let token = session.getIdToken().getJwtToken();
            let response = await window.chat(token, prompt, desiredLevel)
            return JSON.parse(response)
        })
        .catch((error) => console.error(error));
}

export function clear() {
    return Auth.currentSession()
        .then(async session => {
            let token = session.getIdToken().getJwtToken();
            let response = await window.retrieve(token, "true", -1)
            return JSON.parse(response)
        })
        .catch((error) => console.error(error));
}

export function retrieve(desiredLevel) {
    return Auth.currentSession()
        .then(async session => {
            let token = session.getIdToken().getJwtToken();
            let response = await window.retrieve(token, "false", desiredLevel)
            return JSON.parse(response)
        })
        .catch((error) => console.error(error));
}
